// @flow strict
import React from 'react';
import Author from './Author';
import styles from './Content.module.scss';
import ViewCounter from '../ViewCounter';
import StarRating from '../StarRating';

type Props = {
  body: string,
  title: string,
  date: string,
  author: object,
  slug: String,
};

const Content = ({
  body, title, date, author, slug
}: Props) => (
  <div className={styles['content']}>
    <h1 className={styles['content__title']}>{title}</h1>
    <Author author={author} date={date} />
    <div className={styles['content__view-counter']}>
      views: <ViewCounter slug={slug} />
    </div>
    <div className={styles['content__view-counter']}>
      <StarRating slug={slug} /> <em>Click to Rate</em>
    </div>
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
