// @flow strict
import React from 'react';
import { withPrefix } from 'gatsby';
import Meta from '../../Meta';
import { getContactHref } from '../../../../utils';
import styles from './Author.module.scss';


type Props = {
  author: {
    name: string,
    bio: string,
    photo: string,
    contacts: Object,
  },
};

const Author = ({ author, date }: Props) => (
  <div className={styles['author']}>
    <img
      src={withPrefix(author.photo)}
      className={styles['author__photo']}
      width="75"
      height="75"
      alt={author.name}
    />
    <div className={styles['post__info']}>
      <a className={styles['author__bio-twitter']}
        href={getContactHref('twitter', author.contacts.twitter)}
        rel="noopener noreferrer"
        target="_blank"
      >
          <strong>{author.contacts.twitter}</strong>
      </a>
      <Meta date={date} />
    </div>
  </div>
);

export default Author;
